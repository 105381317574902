import styles from '@styles/scss/common/_footerLinks.module.scss';
import { loadKraken } from 'lib/functions/loadKraken';
import { FooterLinks } from 'lib/types/item/footerLinks';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import LanguageSwitcher from './languageswitcher';

export default function UserFooterLinks({
    footerLinks,
    currentLang,
    gender
}: {
    footerLinks: FooterLinks;
    currentLang: string;
    gender: string;
}): JSX.Element {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(window.innerWidth);
        loadKraken('No Performer');
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [width]);

    return (
        <>
            <div className={styles.footer}>
                <div className={styles.col1}>
                    <div>{footerLinks.footer_left_title}</div>
                    {footerLinks.footer_links_left.map((f) => (
                        <a
                            key={f.label}
                            href={`https://rabbits.webcam/?id=2333&product_id=36&tag=signup&passthru1=${gender}&passthru2=${currentLang}&exid={exid}&tfid={tfid}`}>
                            <span>• {f.label}</span>
                        </a>
                    ))}
                </div>
                <div className={styles.col2}>
                    <div className={styles.languageDropdown}>
                        <div>{footerLinks.footer_language_title}</div>
                        <LanguageSwitcher currentLang={currentLang} />
                    </div>
                    <div>{footerLinks.footer_right_title}</div>
                    <a
                        key={footerLinks.footer_links_right[0].label}
                        href={footerLinks.footer_links_right[0].url}>
                        <span>• {footerLinks.footer_links_right[0].label}</span>
                    </a>
                </div>
                <div className={styles.col3}>
                    <div className={styles.logos}>
                        <a
                            href="https://www.rtalabel.org/?content=validate&ql=rab58240&rating=RTA-5042-1996-1400-1577-RTA"
                            aria-label="rta">
                            <svg
                                width="54"
                                height="20"
                                viewBox="0 0 54 20"
                                fill="none"
                                xmlns="https://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 20V0H46.5L54 20H47.5L46.5 17H39.5L38.5 20H32.5L38 5H30V20H23.5V5H18C18 8.5 15.5 10.5 13.5 11C15 11.5 18 16.6667 19.5 20H12C11.5 17 8 11.5 6 12V20H0ZM6 4.15999C6 4.15999 11.5 3.31998 11.5 6.15999C11.5 9 6 8.15999 6 8.15999V4.15999ZM43 6L41 13H45L43 6Z"
                                    fill="grey"
                                />
                            </svg>
                        </a>
                        <a
                            href="https://www.asacp.org/?content=validate&ql=rab58240"
                            aria-label="asacp">
                            <svg
                                version="1.0"
                                xmlns="https://www.w3.org/2000/svg"
                                width="90.990"
                                height="20"
                                viewBox="0 0 250 55">
                                <path
                                    d="M65.6 1.1c-13 3.1-17 17.9-7 25.8 1.5 1.2 6.5 3.3 11.2 4.8C81.5 35.3 84.4 38.6 80 43c-1.9 1.9-3.1 2-15.1 1.8l-13.1-.3L44.1 23 36.4 1.5l-8.6-.3-8.5-.3-6.6 18.8C9.1 30 4.8 42.2 3.1 46.7L.1 55H16l1.1-4.5 1.1-4.5h18.5l1.3 4.6 1.3 4.5 22.7-.3 22.7-.3 4.1-2.7c2.3-1.6 4.9-4.3 5.9-6 2.4-4.7 2.2-12.5-.5-16.9-2.4-3.9-5.3-5.5-18.2-9.7-8.7-2.8-10.6-4.6-8-7.7 1-1.2 5.2-1.5 22.6-1.5 11.8 0 21.4.3 21.4.6s-3.4 10.1-7.5 21.6C100.4 43.7 97 53.6 97 54.1c0 .6 3.3.9 7.7.7l7.8-.3.9-4.3.9-4.2H133l1.2 4.5 1.2 4.5h24.2c14.7 0 25.4-.4 27.1-1.1 3.8-1.4 8.1-5.6 10.4-10.1 2.8-5.6 2.5-6.4-3.3-8.4-2.9-.9-5.8-1.9-6.4-2.1-.7-.2-1.7 1.2-2.4 3.1-1.5 4.6-4.4 6.6-9.5 6.6-3.2 0-4.7-.6-6.6-2.6-2.1-2.2-2.4-3.7-2.7-11.2-.3-7.4 0-9.2 1.8-12 1.1-1.8 3.2-3.7 4.6-4.2 1.5-.6 9.5-.9 18.3-.8l15.6.3.3 21.2.2 21.3h14.9l.3-10.3.3-10.2 9-.5c10-.6 12.5-1.7 15.8-7 2.7-4.4 2.9-13.8.4-18.7-3.5-6.7-4.2-6.8-44.4-7.1-34.2-.3-36.4-.2-40 1.7-5 2.5-7.7 5.6-10.4 11.6-2.8 6.1-3.1 18.6-.6 25.3l1.5 4.3-3.1-.3-3.2-.3-7.6-21.5L132.3.5 100.9.3c-17.3 0-33.1.3-35.3.8zm167.7 13c3.9 4.8.5 9.9-6.8 9.9H222v-5.9c0-3.8.5-6.1 1.3-6.5 2.4-1.1 8.2.4 10 2.5zM30.5 24c1.4 4.6 2.5 8.7 2.5 9.2 0 .4-2.5.8-5.6.8-4.8 0-5.5-.2-5-1.8.3-.9 1.5-5.2 2.7-9.5 1.2-4.2 2.4-7.6 2.6-7.4.2.2 1.4 4.1 2.8 8.7zm96.3.2c1.2 4.6 2.2 8.6 2.2 9 0 .5-2.5.8-5.6.8-4.7 0-5.5-.3-5-1.6.3-.8 1.6-4.9 2.7-9 1.2-4.1 2.5-7.4 2.8-7.4.4 0 1.7 3.7 2.9 8.2z"
                                    fill="grey"></path>
                            </svg>
                        </a>
                    </div>
                    {currentLang === 'en'
                        ? footerLinks.footer_links_right.slice(1).map((f) => (
                              <Link
                                  key={f.label}
                                  href={
                                      process.env.NEXT_PUBLIC_APP_URL + 'legal/' +
                                      f.url.split('/')[3].split('.')[0]
                                  }>
                                  <span>• {f.label}</span>
                              </Link>
                          ))
                        : footerLinks.footer_links_right.slice(1).map((f) => (
                              <Link
                                  key={f.label}
                                  href={
                                      process.env.NEXT_PUBLIC_APP_URL +
                                      currentLang +
                                      '/' + 'legal/' +
                                      f.url.split('/')[4].split('.')[0] +
                                      '/'
                                  }>
                                  <span>• {f.label}</span>
                              </Link>
                          ))}
                </div>
            </div>
        </>
    );
}
