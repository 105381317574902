import styles from '@styles/scss/common/_left-nav.module.scss';
import home from 'lib/functions/homeFunction';
import homeStyle from 'lib/functions/homeStyle';
import { SidebarItem } from 'lib/types/item/sideBarItem';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export default function LeftNav({
    sideBarItems,
    currentLang,
    getLeftnav,
    leftnavStatus,
    searchStatus
}: {
    sideBarItems: SidebarItem[];
    currentLang: string;
    getLeftnav: any;
    leftnavStatus: any;
    searchStatus: any;
}): JSX.Element {
    const [open, setOpen] = useState(leftnavStatus);
    const [svg, setSvg] = useState(true);
    const [width, setWidth] = useState(0);
    const router = useRouter();

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResizeWindow);

        if (searchStatus) {
            setOpen(false);
            setSvg(true);
            getLeftnav(false);
        }
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [searchStatus]);

    const conditions = [
        width > 1280 ? '/live/[username]' : '',
        '/legal/[page]',
        '/trans'
    ];
    return (
        <div className={styles.sideBarContainer}>
            {!router.asPath.includes('trans') ? (
                <button
                    className={styles.menuButton}
                    aria-label="hamburger"
                    onClick={() => {
                        setOpen(!open);
                        setSvg(!svg);
                        getLeftnav(!open);
                    }}>
                    {svg ? (
                        <svg
                            width="25"
                            height="20"
                            viewBox="0 0 25 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <line y1="2" x2="25" y2="2" stroke="#0D381C" strokeWidth="2" />
                            <line y1="10" x2="25" y2="10" stroke="#0D381C" strokeWidth="2" />
                            <line y1="18" x2="25" y2="18" stroke="#0D381C" strokeWidth="2" />
                        </svg>
                    ) : (
                        <svg
                            width="25"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.02 19.9H15.2C15.0133 19.9 14.8667 19.8467 14.76 19.74C14.6533 19.62 14.5667 19.5067 14.5 19.4L8.46 10.58C8.40667 10.82 8.30667 11.0533 8.16 11.28L2.4 19.4C2.30667 19.52 2.20667 19.6333 2.1 19.74C2.00667 19.8467 1.88 19.9 1.72 19.9H0L7.24 9.72L0.28 0H2.1C2.28667 0 2.42667 0.0400003 2.52 0.120001C2.61333 0.200001 2.69333 0.293334 2.76 0.400001L8.6 8.82C8.62667 8.71333 8.66667 8.6 8.72 8.48C8.78667 8.36 8.85333 8.24 8.92 8.12L14.38 0.420001C14.46 0.300001 14.5467 0.200001 14.64 0.120001C14.7333 0.0400003 14.8533 0 15 0H16.74L9.8 9.6L17.02 19.9Z"
                                fill="#0D381C"
                            />
                        </svg>
                    )}
                </button>
            ) : null}
            {!conditions.some((i) => router.route === i) && !router.asPath.includes('trans') ? (
                <div
                    className={width > 1280 ? styles.menu : open ? styles.menu : styles.menuMobile}>
                    <Link href={home(router.asPath, currentLang)}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                            role="presentation"
                            onClick={() => (setOpen(false), setSvg(true), getLeftnav(false))}
                            className={homeStyle(router.asPath, styles)}>
                            <span>{sideBarItems[0].category_name}</span>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="https://www.w3.org/2000/svg">
                                <path
                                    d="M1 1L6 6L1 11"
                                    stroke="#000"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </Link>
                    {sideBarItems.slice(1, sideBarItems.length).map((sideBarItem) => (
                        <Link
                            key={sideBarItem.category_name}
                            href={
                                currentLang === 'en'
                                    ? process.env.NEXT_PUBLIC_APP_URL + sideBarItem.url
                                    : process.env.NEXT_PUBLIC_APP_URL +
                                      currentLang +
                                      '/' +
                                      sideBarItem.url
                            }>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                                role="presentation"
                                onClick={() => (setOpen(false), setSvg(true), getLeftnav(false))}
                                className={
                                    router.asPath === `/${sideBarItem.url}` ||
                                    router.asPath.split('/')[1] + '/' === `${sideBarItem.url}`
                                        ? styles.categoryActive
                                        : styles.category
                                }>
                                <span>{sideBarItem.category_name}</span>
                                <svg
                                    width="7"
                                    height="12"
                                    viewBox="0 0 7 12"
                                    fill="none"
                                    xmlns="https://www.w3.org/2000/svg">
                                    <path
                                        d="M1 1L6 6L1 11"
                                        stroke="#000"
                                        strokeMiterlimit="10"
                                        strokeLinecap="square"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                        </Link>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
